import React from 'react';

const SimplePDFViewer = ({ file }) => {
  return (
    <div className="pdf-viewer-container" style={{
      width: '100%',
      height: '80vh', // Adjust this value as needed
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff', // Set background to white
      border: 'none', // Remove any border
    }}>
      <object
        data={file}
        type="application/pdf"
        width="100%"
        height="100%"
        style={{
          border: 'none', // Remove object border
        }}
      >
        <p>It appears you don't have a PDF plugin for this browser. 
        You can <a href={file}>click here to download the PDF file.</a></p>
      </object>
    </div>
  );
};

export default SimplePDFViewer;