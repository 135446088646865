import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import '../index.css'; // Adjust the path as necessary
import DropdownTextBox from './DropdownTextBox';
import ReactMarkdown from 'react-markdown';
import SimplePDFViewer from './SimplePDFViewer';
import "tailwind-pdf-viewer/style.css";

import AboutVeganismPDF from '../pictures/PDFs/About Veganism updated.pdf';
import CIEssayQuantifiabilityPDF from '../pictures/PDFs/CIEssayQuantifiability.pdf';
import renderPDF from './MobilePDFViewer';
import EssayFairnessInLovePDF from '../pictures/PDFs/FairnessInLove.pdf';















import EssayJudgementPDF from '../pictures/PDFs/Essay3.pdf';



const PDFViewer = lazy(() => import('tailwind-pdf-viewer'));
{/* <div style={{ wid: '50vh' }}>
<Suspense fallback={<div>Loading PDF...</div>}>
  <SimplePDFViewer file={AboutVeganismPDF} />
</Suspense>
</div> */}

const Essays = () => {




const [openIndexEssays, setOpenIndexEssays] = useState(null);
const textareaRef = useRef(null); // Create a ref for the textarea
const handleToggleEssays = (index) => {
setOpenIndexEssays(openIndexEssays === index ? null : index);

  };

const ContentHowToApologize = () => (
    <div>

        <p>


        - <strong>-3 Denial and Self-Victimization, Counter accusation, gaslighting</strong><br />
- <strong>-2 Minimizing and Justifying</strong><br />
- <strong>-1 Apologizing but Deflecting Responsibility</strong><br />
- <strong>0 Silence and Minimal Acknowledgment:</strong><br />
- <strong>1 Acknowledging and Taking Responsibility</strong><br />
- <strong>2 Understanding, Explaining how your mistake has affected the other person.</strong><br />
- <strong>3 Commitment to Change:</strong><br /><br />

<strong>So step by step:</strong><br /><br />

1. Calm down, ask yourself how you could be wrong in your immediate emotional reaction after being confronted with that you hurt someone or made a mistake.<br />
<br />
2. Apologize and say you didn't intend.<br /> <br />
3. Examine how and why it happened, explain to the other person without excusing yourself, also say “this is not an excuse” but also check if it is.<br /> <br />
4. Examine how the other person must have felt like and explain it to them, ask if that's correct how you see it.<br /><br />
5. Imagine a situation in which you could contradict with what you are and how you are now. Don't step back from your apologies. At the same time, don't hold back objections and parts of the mistakes that you feel you need validation for, cause those, if rightful, will come up at other times and will hurt your conversant. Stay consistent with the settlement you find with the other party.<br /><br />
6. Imagine how to avoid the mistake the next time you face such a situation.<br /><br />
7. Work on damage control constantly (some part of the solution after a mistake or damage is to acknowledge and then to reinforce the apology and understanding through communication and action).<br /><br />
8. Actually commit to behave and think differently next time.<br /><br />
9. Remember you might forgive through understanding quite quickly, but you won't be able to forget timely. Understanding will only transform the anger into pain.<br /><br />




            <strong>The Range of Responses with examples:</strong><br /><br />
            
            - <strong>Denial and Self-Victimization:</strong><br />
                - Denial of reality.<br />
                - Inverting situations to make you question your own perception.<br />
                - Self-victimizing: mistakes happen while being a victim yourself, often unaware of your own wrongdoings.<br />
                - Offering excuses.<br />
                - Attacking back.<br /><br />

            - <strong>Minimizing and Justifying:</strong><br />
                - Making excuses, saying it's not that bad.<br />
                - Claiming the right to their actions.<br /><br />

            - <strong>Silence and Minimal Acknowledgment:</strong><br />
                - Not saying anything.<br />
                - Just saying "sorry."<br /><br />

            - <strong>Deflecting Responsibility:</strong><br />
                - Saying "sorry that you feel that way."<br />
                - Apologizing for the other person's understanding of the situation.<br />
                - Saying "sorry that I made you feel that way."<br /><br />

            - <strong>Acknowledging and Taking Responsibility:</strong><br />
                - Apologizing with full acknowledgment: "Sorry that this happened. It's my fault."<br />
                - Expressing understanding: "I understand this made you feel like that. I guess it’s normal that you react this way, and it’s not acceptable what I have done."<br />
                - Admitting unintentional harm and recognizing a part of you that acted knowingly.<br /><br />

            - <strong>Understanding and Commitment to Change:</strong><br />
                - Explaining how your mistake has affected the other person.<br />
                - Asking if your understanding of the impact is correct.<br />
                - Showing genuine validation by understanding and explaining the other's feelings.<br />
        </p>
    </div>
);



const PoemSolang = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Gedicht - Solang</strong><br/>
            (2024)<br />
            <em>an mich und die da draußen</em><br /><br />

            Solang mein Glück deinem Schmerz die Weichen stellt,<br />
            Solang mein Schmerz dein Schicksal hellt,<br />
            Solang mein Schicksal deinem Schmerz verfällt,<br />
            Solang mein Schmerz deinem Glück die Weichen stellt,<br />
            Ist mir alles egal<br /><br />

            Auch wenn Glück dem Schmerz den Rücken kehrt<br />
            über meinen wirst du nie belehrt<br />
            doch wär ein Danke schön<br />
            Nein, noch kein Dankeschön<br />
            Der Schmerz - noch in der Reife, Das Glück noch in der Weite<br />
            Und so warte ich und stelle in der Stille deine Weiche<br /><br />

            Wie wäre es wenn ich mich nicht im Schatten fand<br />
            Wenn die da draußen diesem folgten im Verstand,<br />
            Wenn nicht mir, dann denen widm’ich dies Gedicht<br />
            Warum das Glück mit Stille treibt und sie nicht bricht<br />
            Ist zu verstehen mein Herzens halber Willen<br />
            Der Rest ist dieses Wissen in Tat zu bringen<br /><br />

            Ich habe Glück, ich breche Stille, ich finde Helle,<br />
            Bald ich meine Worte (nie) wieder in stille stelle,<br />
            So ist mein später’s ich,<br />
            kein Werdenichts<br />
        </p>
    </div>
);

const PoemKurzweiligLangweilig = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Gedicht - Kurzweilig Langweilig</strong><br/>
            (2024)<br /><br />

            Kurzweilig schien mein Herz langweilig,<br />
            Außen Rau, Mitte flüchtig, innen Seidig<br /><br />

            Es war gerade entschlossen verschlossen,<br />
            plötzlich so langsam war es zerbrochen.<br />
            Und wieder <br />
            Und wieder <br />
            Und wieder ein letztes Mal <br />
            Und wieder <br />
            Das war mein Jahr und wir sind beide Verlierer<br /><br />

            Gehört hat es Lust<br />
            Gefühlt hat es Frust<br />
            Geglaubt hat es schlicht<br />
            Gesehen hat es nicht<br />
            Wahrlich war alles gemischt<br />
            Braucht es dies auf lange Sicht?<br /><br />
            <br /><br />
            English Version <br /><br />
            <strong style={{ fontSize: '1.5em' }}>Poem - Ephemeral Dullness</strong><br /><br />


            My heart seemed transient, yet dreary,<br />
            Outside rough, midst fleeting, inside silky.<br /><br />

            It was openly closed then tightly chosen,<br />
            Suddenly so slow, it was broken<br /><br />

            It heard desire,<br />
            Felt the fire of ire,<br />
            Believed with ease,<br />
            Saw none of these.<br />
            Truly, all was a mixed plight,<br />
            Does it need this, in the long night?<br /><br />
        </p>
    </div>
);

const PoemGefangen = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Gedicht - Gefangen</strong><br/>
            (2024)<br /><br />

            Mal wieder entzückt sich Unrecht von meiner Würde,<br />
            Verschleiernd umgibt mich das Unrecht an meiner Liebe,<br />
            Darum verlangt es mich, als ich nichts spürte,<br />
            Doch wie lang, bis ich nicht mehr weiter spiele,<br /><br />

            Gefangen zwischen meinem Schmerz und ihrer Qual,<br />
            So muss ich beistehen nur einer Übelkeit,<br />
            Die meiner Liebe ist meine Wahl,<br />
            Doch wie lang, bis mein Unrecht Ihres übersteigt<br />
            Nie<br /><br />
        </p>
    </div>
);

const PoemDeepStitches = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Deep Stitches</strong><br/>
            (2024)<br /><br />

            Hang in there - to all in pain<br />
            Greetings of the never knowing<br />
            I hear me saying  - you won’t go insane<br />
            From the wasted life - I am sowing<br /><br />

            Who am I to - continue stitching<br />
            Just to open your life red seam<br />
            To not see you - as my sibling<br />
            Fight for your life to redeem<br /><br />

            We’re not made to stitch on multilayers<br />
            First hidden and then uncovered<br />
            At the end - As if nothing matters<br />
            Cause we are safely buffered<br /><br />

            The threads of feelings, don't see the depths<br />
            The distant wrinkles nor the backside<br />
            Only mirrors instead of lenses<br />
            Will prevent a fatal landslide<br /><br />

            How is that<br />
            You don't hate<br />
            Humanity at its core<br />
            Is one thing only<br />
            The act around desires<br />
            Without morals<br /><br />

            A factory of sewing machines<br />
            Without caring about the mess on the inseam<br /><br />
        </p>
    </div>
);

const PoemBloomingsOfAFlowerGarden = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Bloomings of a Flower Garden</strong><br/>
            (2024)<br /><br />
            Once there was a rose and a seedling<br />
            Tribe together in the wind of feelings<br />
            Love in both and both in love<br />
            They paint their faith with colors of<br />
            Their lively lives and worthy goals<br />
            They bind their hearts and join their souls<br />
            For feelings fleeing from the jargon<br />
            For bloomings of a flower garden<br /><br />

            Wishes of tribes to start mingling<br />
            Wishes to witness ended in bridling<br />
            With spading the roots by lying<br />
            With carvings to mark a striking<br />
            To grow into pleasing the garden<br />
            But feelings were dismissed, left hardened<br /><br />

            Seasons pass to anew the cycle<br />
            Settling into a life adjourned<br />
            Downing the soil never returned<br />
            Still<br />
            Stretching roots into the void<br />
            Still<br />
            Gifting fruits to never return<br />
            Sprawling branches to reach a pardon<br />
            Painting with faith into a garden<br />
            Echoing ongoing spade stitches<br />
            But missing the promise to witness<br />
        </p>
    </div>
);


const PoemFirstForMyselfNegativeUtilitarianism = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>First Poem for Myself - Negative Utilitarianism</strong><br/>
            (2023)<br /><br />
            Please help me to avoid what is bad for me<br />
            Please help me to avoid what is bad for both of us<br />
            Please help me to avoid what is bad for all of us<br /><br />

            Who ever you are<br />
            A stranger with privilege<br />
            A loved one<br />
            A lucky one<br />
            A figure of attention<br />
            A chosen one in power<br /><br />

            Please care for this, so that I can be good<br />
            to myself,<br />
            to both of us,<br />
            to all of us<br /><br />

            Sincerely<br />
            The weak failing me<br />
        </p>
    </div>
);


const PoemAufWiedersehen = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Auf Wiedersehen</strong><br/>
            (2021) <br /><br />
            Ein Gemälde, eine Schrift, ein Duft<br />
            Eine reißend kalte Kluft<br />
            Zwei lauwarme Erinnerungen<br />
            Entstanden in latenter Wärme<br />
            Bald thermisch entkoppelt<br /><br />

            Zeitlich versetzt, ungleich verletzt<br />
            Entblößt durch ungewisse<br />
            Minuten, in schweigen<br />
            Morgende leerer Blicke<br />
            Voller Stimmen die reißen<br />
            In Monate mit nur einer Bitte<br />
            Ich will mehr<br />
            Ich will nicht mehr<br /><br />

            Altes Ich, wer wollte ich sein?<br />
            Und wer nicht?<br />
            Je mehr man das weiß,<br />
            Umso mehr weiß man,<br />
            Was man nicht weiß<br />
            Wie immer<br /><br />

            Ich sage auf Wiedersehen<br />
            Wiedersehen auf unbestimmte Zeit<br />
            Wiedersehen Kunst, der Verarbeitung<br />
            Wiedersehen von zeitloser Zeit<br />
            Auf Wiedersehen von dem hier<br />
        </p>
    </div>
);

const PoemDerPantherDansLesJardinsDuPostmoderne = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Der Panther</strong> <br />Dans les jardins du postmoderne(2021) <br /><br />
            Sein Blick - vom vorbeiziehen der Berge<br />
            so müd geworden, daß er nichts mehr hält.<br />
            Ihm ist, als ob die Berge endlos wären<br />
            und unter diesen Bergen liegt kein Wert.<br /><br />

            Ein seichter Klang, verschallt mit leerem Sinne<br />
            in ein Leben das sich im kleinsten Kreise dreht,<br />
            gleichend einem Kampf um eine Mitte,<br />
            in der betäubt ein großer Wille steht.<br /><br />

            Nur manchmal lassen seine Berge, Licht in seine Leere<br />
            Licht in die verlorene Zeit, verlorener Türen<br />
            Umhüllt die Kruste dieser Seele<br />
            und fängt im Herzen an zu blühen.<br />
        </p>
    </div>
);


const ContentAboutSuccess = () => (
    <div style={{ wid: '50vh' }}>
        <Suspense fallback={<div>Loading PDF...</div>}>
            {window.innerWidth <= 768 ? (
                <div>
                    <p>It appears you are using a mobile device. Click <a href={EssayJudgementPDF} target="_blank" rel="noopener noreferrer"><span style={{ color: 'orange', fontWeight: 'bold' }}>here</span></a> to open/download the PDF file.</p>
                </div>
            ) : (
                <SimplePDFViewer file={EssayJudgementPDF} />
            )}
        </Suspense>
    </div>
);

const EssayCIOnValueSystemsAndQuantifiability = () => (
    <div style={{ wid: '50vh' }}>
        <Suspense fallback={<div>Loading PDF...</div>}>
            {window.innerWidth <= 768 ? (
                <div>
                    <p>It appears you are using a mobile device. Click <a href={CIEssayQuantifiabilityPDF} target="_blank" rel="noopener noreferrer"><span style={{ color: 'orange', fontWeight: 'bold' }}>here</span></a> to open/download the PDF file.</p>
                </div>
            ) : (
                <SimplePDFViewer file={CIEssayQuantifiabilityPDF} />
            )}
        </Suspense>
    </div>
);

const ContentVeganism = () => (
    <div style={{ wid: '50vh' }}>
        <Suspense fallback={<div>Loading PDF...</div>}>
            {window.innerWidth <= 768 ? (
                <div>
                    <p>It appears you are using a mobile device. Click <a href={AboutVeganismPDF} target="_blank" rel="noopener noreferrer"><span style={{ color: 'orange', fontWeight: 'bold' }}>here</span></a> to open/download the PDF file.</p>
                </div>
            ) : (
                <SimplePDFViewer file={AboutVeganismPDF} />
            )}
        </Suspense>
    </div>
);

const EssayUnderstandingDesiresAndFairness = () => (
    <div style={{ wid: '50vh' }}>
        <Suspense fallback={<div>Loading PDF...</div>}>
            {window.innerWidth <= 768 ? (
                <div>
                    <p>It appears you are using a mobile device. Click <a href={EssayFairnessInLovePDF} target="_blank" rel="noopener noreferrer"><span style={{ color: 'orange', fontWeight: 'bold' }}>here</span></a> to open/download the PDF file.</p>
                </div>
            ) : (
                <SimplePDFViewer file={EssayFairnessInLovePDF} />
            )}
        </Suspense>
    </div>
);


const dropdownContentsEssays = [
    
    { id: 1, title: "Fairness in Love", content: <EssayUnderstandingDesiresAndFairness /> },

    { id: 2, title: "About Veganism and Alt Proteins", content: <ContentVeganism /> },

    { 
        id: 3, 
        title: "About Success", 
        content: <ContentAboutSuccess />
      },
      { id: 4, title: "On Value Systems and Their Quantifiability", content: <EssayCIOnValueSystemsAndQuantifiability /> },
      { id: 5, title: "How to Apologize", content: <ContentHowToApologize /> },

      { id: 6, title: "Being in tune or hurting people (working on it)", content: "" },
      { id: 7, title: "Meaning of Life and Meaning of Love (working on it)", content: "" },
      { id: 8, title: "My two expectations to my children (working on it)", content: "" },

      
      
    { id: 9, title: "Gedicht - Solang", content: <PoemSolang /> },
    { id: 10, title: "Poem - Deep Stitches", content: <PoemDeepStitches /> },
    { id: 11, title: "Gedicht - Gefangen", content: <PoemGefangen /> },
    { id: 12, title: "Poem - Ephemeral Dullness", content: <PoemKurzweiligLangweilig /> },
    { id: 13, title: "Poem - Bloomings of a Flower Garden", content: <PoemBloomingsOfAFlowerGarden /> },
    { id: 14, title: "Poem - First Poem for Myself - Negative Utilitarianism", content: <PoemFirstForMyselfNegativeUtilitarianism /> },
    { id: 15, title: "Gedicht - Auf Wiedersehen", content: <PoemAufWiedersehen /> },
    { id: 16, title: "Gedicht - Der Panther Dans les jardins du postmoderne", content: <PoemDerPantherDansLesJardinsDuPostmoderne /> },

    // { id: 14, title: "Kinds of Influence (working on it)", content: "" },
  
    // { id: 17, title: "How to make alt protein cool (working on it)", content: `
   
    // ` },
    // { id: 18, title: "Previledge of Aknowledgement (working on it)", content: "Content discussing the privileged position of acknowledgment. The position of acknowledgement is a privileged one" },
    // { id: 19, title: "About value of altruism (working on it)", content: "Content discussing four square graph and the value of altruism." },
    // { id: 20, title: "About Sustainability Ethics (working on it)", content: "Content discussing different ethical fundaments: Biocentirsm, Geocentrism, Anthropocentrism." },
    // { id: 21, title: "Societal change Psychology (working on it)", content: "Patterns of my mum, psychological effects and phases of discovering something like this." },
     ];





  return (
    


  <div className="">
      <div className="pb-10 px-0">

 
        <div>
            {dropdownContentsEssays.map(({ id, title, content }) => (
            <div key={id} className={`${openIndexEssays === id ? 'mb-6 mt-2' : 'mb-0'}`}>
                <DropdownTextBox
                title={` ${title}`}
                isOpen={openIndexEssays === id}
                toggleDropdown={() => handleToggleEssays(id)}
                >
                
            <div className="markdown-content text-[10px] md:text-sm">
               {content}
            </div>

            </DropdownTextBox>
            </div>
            ))}
        </div>

    </div>
    </div>











  );
};

export default Essays;

