import { BlobProvider } from '@react-pdf/renderer';
import { Document, Page } from 'react-pdf';
const renderPDF = (MyDocument, handleLoadSuccess) => {
  return (
    <BlobProvider document={<MyDocument />}>
      {({ blob, url, loading }) => {
        return (
          <Document
            file={url}
            onLoadSuccess={(pdf) => handleLoadSuccess(pdf, blob)}
            renderMode="canvas"
          >
            <Page pageNumber={1} width={window.innerWidth * 0.9} />
          </Document>
        );
      }}
    </BlobProvider>
  );
};