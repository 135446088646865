import React, { useState, useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faLock, faBolt, faFistRaised, faPeace} from '@fortawesome/free-solid-svg-icons';



const LandingPage = () => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [showTypewriter, setShowTypewriter] = useState(false);
  const typewriterRef = useRef(null);

  const handleBoxClick = (box) => {
    setSelectedBox(box);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('start-animation');
        }
      });
    });

    document.querySelectorAll('.animate-on-scroll').forEach((element) => {
      observer.observe(element);
    });

    // Set a timeout to show the typewriter effect after 3 seconds
    setShowTypewriter(true);
  }, []);
  


  const firstPhrase = `Hey Welcome! <br />This is meant as a curated overview of my work and interests for my potential/future collaborators, friends and partner.<br />`;

  return (
    <div className="w-full">

      <div className="w-full h-2">
        <h2 className="text-sm text-black md:text-lg lg:text-lg xl:text-lg text-left">
          {showTypewriter && (
            <Typewriter
              options={{
                autoStart: true,
                loop: false,
                delay: 15,
                cursor: '',
              }}
              onInit={(typewriter) => {
                typewriterRef.current = typewriter;
                typewriter
                  .typeString(firstPhrase)
                  .start();
              }}
            />
          )}
        </h2>
      </div>

          </div>
      
        

      
  
  );
};

export default LandingPage;
