import React from 'react';

const DropdownTextBox = ({ title, children, isOpen, toggleDropdown, year, activeYN }) => {
  return (
    <div className="w-full mx-auto my-[-30px] md:my-[-35px] mb-10 md:mb-5 overflow-hidden transition-all duration-500 ease-in-out" style={{ 
      backgroundColor: isOpen ? 'rgba(0, 0, 0, 0.05)' : 'transparent', 
      borderRadius: '10px', 
      marginBottom: isOpen ? 42 : "0px",
      border: isOpen ? '1px solid transparent' : 'none',
      animation: isOpen ? 'rainbow-border 5s infinite' : 'none',
      boxShadow: isOpen ? '0 0 10px rgba(255, 0, 0, 0.5)' : 'none'
    }}>
      <div className="flex justify-start items-start cursor-pointer py-2" onClick={toggleDropdown}>
        <div className={`transform transition-transform duration-300 ${isOpen ? 'rotate-90' : ''} mr-2`}>
          <svg
            className="w-5 h-5 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </div>
        <div className="flex flex-col">
          <h3 className={`text-sm md:text-base lg:text-base xl:text-lg text-left ${isOpen ? 'font-bold' : ''} leading-tight md:leading-normal`}>
            {title} <span className="font-bold">{activeYN}</span>
          </h3>
        </div>
      </div>
      <div className={`p-4 text-xs md:text-base lg:text-base xl:text-base text-left transition-opacity duration-300  ${isOpen ? 'p-3 md:p-10' : 'p-3 md:p-4'}  ${isOpen ? 'opacity-100' : 'opacity-0'}`} style={{ maxHeight: isOpen ? '1000px' : '0', overflowY: 'auto' }}>
        {children}
      </div>
    </div>
  );
};

export default DropdownTextBox;
